import { Chip, Tooltip } from "@mui/material"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Actor } from "../../api/Authz"
import { CustomerActors } from "../../api/portal/hooks/Actors"
import { wideTooltip } from "../../config/sizing"
import { formatUserShort } from "../../widgets/format"
import { ActorIcon } from "./ActorIcon"
import { UserTooltip } from "./UserTooltip"

export interface UserChipProps {
    user: Actor
    customerActors: CustomerActors
}

export const UserChip = (props: UserChipProps) => {
    const { user, customerActors } = props

    const { t } = useTranslation()
    const u = useMemo(() => customerActors.asUser(user), [customerActors, user])

    return (
        <Tooltip title={<UserTooltip user={u} />} componentsProps={wideTooltip}>
            <Chip icon={<ActorIcon actor={user} />} label={formatUserShort(t, u)} sx={{ pl: 1 }} />
        </Tooltip>
    )
}
