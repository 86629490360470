import { Grid, Stack, Switch, Tooltip, Typography, useTheme } from "@mui/material"
import { t } from "i18next"

export interface OutputInfoProps {
    label: string
    tooltip: string
    disabled: boolean
    outputSwitch: boolean
    flipSwitch: (on: boolean) => void
}

export const OutputInfo = (props: OutputInfoProps) => {
    const { label, tooltip, disabled, outputSwitch, flipSwitch } = props
    const theme = useTheme()

    return (
        <Grid item xs={12} sm={6} md={4} lg={3}>
            <Stack
                direction="row"
                px={1}
                height="100%"
                sx={{ alignItems: "center", backgroundColor: theme.palette.background.paper }}
            >
                <Stack
                    direction="row"
                    sx={{
                        alignItems: "center",
                        flexGrow: 1,
                        overflow: "hidden",
                    }}
                >
                    <Tooltip
                        title={outputSwitch ? t("action.switch_off_tooltip") : t("action.switch_on_tooltip")}
                        disableInteractive
                    >
                        <Switch disabled={disabled} checked={outputSwitch} onClick={() => flipSwitch(outputSwitch)} />
                    </Tooltip>
                    <Tooltip title={tooltip} disableInteractive>
                        <Typography
                            sx={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {label}
                        </Typography>
                    </Tooltip>
                </Stack>
            </Stack>
        </Grid>
    )
}
