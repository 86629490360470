export enum ItemState {
    Unknown = "unknown",
    Partial = "partial",
    Off = "off",
    On = "on",
}

export interface SiteAlarmState {
    SiteID: number
    Armed: ItemState
    Units: UnitAlarmState[]
}

export interface UnitAlarmState {
    UnitID: number
    Armed: ItemState
    PowerSaving: ItemState
}
