import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Event, Labels, ToEventDetails, asLabels } from "../api/Event"
import { Second } from "../config/time"
import { monoEndpointURL } from "../config/urls"
import { useBackoff } from "../hooks/backoff"

const reconnectDelay = 5 * Second

export const allEvents = () => true

interface StreamThing {
    thing?: ThingEvent
}

interface ThingEvent {
    name: string
    manifest: Manifest
}

interface Manifest {
    timestamp: string
    clientId: string
    labels: Object
    annotations?: Annotations
}

interface Annotations {
    display_name: string
}

export interface Thing {
    thing: string
    displayName?: string
    labels: Labels
}

interface StreamEvent {
    value?: ValueEvent
}

interface ValueEvent {
    item: string
    thing: string
    value: Value
}

interface Value {
    clientId: string
    labels: Object
    timestamp: string
    operation: "CHANGED" | "INITIALIZED"
    number?: number
}

interface Value {
    clientId: string
    labels: Object
    timestamp: string
    operation: "CHANGED" | "INITIALIZED"
    number?: number
}

export function useAlarmEvent(
    siteID: number,
    unit: string | undefined,
    active: boolean,
    stale: boolean,
    filter: (event: Event) => boolean,
    onThing: (event: Thing) => void,
    onEvent: (event: Event) => void,
    onConnected?: () => void,
    onDisconnected?: () => void
) {
    const { t } = useTranslation()
    const [online, setOnline] = useState(false)
    const { attempt, retry, cancel } = useBackoff(reconnectDelay)

    const handleConnect = useCallback(() => {
        console.log(`Event stream for site ${siteID} connected.`)
        setOnline(true)
        onConnected?.()
    }, [onConnected, siteID])

    const handleDisconnect = useCallback(() => {
        console.log(`Event stream for site ${siteID} disconnected.`)
        setOnline(false)
        onDisconnected?.()
    }, [onDisconnected, siteID])

    useEffect(() => {
        if (!active) {
            return
        }

        const now = new Date()
        const url = unit
            ? monoEndpointURL(`watch/sites/${siteID}/items?thing=${unit}.alarm_*&include=value&include=thing`)
            : monoEndpointURL(`watch/sites/${siteID}/items?include=value&include=thing`)
        const source = new EventSource(url)

        source.onmessage = (event) => {
            if (event.type !== "message") {
                console.log("SSE Event: not a message.", event.type, event.data)
                return
            }

            const thingPayload = JSON.parse(event.data) as StreamThing
            if (thingPayload.thing !== undefined) {
                const parsedThing: Thing = {
                    thing: thingPayload.thing.name,
                    displayName: thingPayload.thing.manifest.annotations?.display_name,
                    labels: asLabels(thingPayload.thing?.manifest.labels),
                }
                console.log("SSE Thing: success.", parsedThing)
                onThing(parsedThing)
                return
            }

            const payload = JSON.parse(event.data) as StreamEvent
            if (payload.value === undefined) {
                console.log("SSE Event: no payload value.", event.data)
                return
            }

            const thing = payload.value.thing
            const item = payload.value.item
            const timestamp = new Date(payload.value.value.timestamp)
            const value = payload.value.value.number
            const labels = asLabels(payload.value.value.labels)
            const clientId = payload.value.value.clientId
            const details = ToEventDetails(thing, item, value, labels, t)

            const parsedEvent: Event = {
                ...details,
                thing,
                item,
                timestamp,
                value,
                labels,
                clientId,
            }

            if (!stale && parsedEvent.timestamp < now) {
                console.log("SSE Event: stale event.", parsedEvent)
                return
            }

            if (!filter(parsedEvent)) {
                console.log("SSE Event: filtered out.", parsedEvent)
                return
            }
            console.log("SSE Event: success.", parsedEvent)
            onEvent(parsedEvent)
        }

        source.onerror = () => {
            handleDisconnect()
            source.close()
            retry()
        }
        source.onopen = () => {
            handleConnect()
        }

        return () => {
            if (source.readyState !== source.CLOSED) {
                source.close()
            }
            setOnline(false)
            cancel()
        }
    }, [
        active,
        unit,
        siteID,
        filter,
        onThing,
        onEvent,
        handleConnect,
        handleDisconnect,
        attempt,
        retry,
        cancel,
        stale,
        t,
    ])

    return {
        online,
    }
}
