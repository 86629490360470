import { LocationOn } from "@mui/icons-material"
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material"
import { Stack } from "@mui/system"
import { useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Site } from "../../api/Customer"
import { IconText } from "./IconText"

export interface FilterSitesDialogProps {
    initialFilter: number[]
    sites: Map<number, Site>
    onClose: () => void
    onSet: (filter: number[]) => void
}

export function FilterSitesDialog(props: FilterSitesDialogProps) {
    const { initialFilter, sites, onClose, onSet } = props

    const [filter, setFilter] = useState<number[]>(initialFilter)
    const { t } = useTranslation()

    const orderedSites = useMemo(
        () => Array.from(sites.values()).sort((s1, s2) => (s1.DisplayName < s2.DisplayName ? -1 : 1)),
        [sites]
    )

    const selected = useMemo(() => new Set(filter), [filter])
    const isSelected = useCallback((s: Site) => selected.has(s.ID), [selected])

    const renderSite = (s: Site) => (
        <ListItem
            key={s.ID}
            onClick={() => setFilter((f) => (isSelected(s) ? f.filter((id) => id !== s.ID) : f.concat([s.ID])))}
            sx={{ p: 0 }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <Checkbox checked={isSelected(s)} size="small" />
                </ListItemIcon>
                <ListItemText>
                    <IconText icon={<LocationOn />} bold text={s.DisplayName} />
                </ListItemText>
            </ListItemButton>
        </ListItem>
    )

    return (
        <Dialog onClose={onClose} aria-labelledby="filter-sites-dialog-title" open={true}>
            <DialogTitle id="filter-sites-dialog-title">{t("dialog.permission.filter_actors_title")}</DialogTitle>
            <Divider />
            <DialogContent>
                <Stack direction="row" spacing={1}>
                    <List dense>
                        <ListItem>
                            <ListItemText>
                                <Typography>{t("field.sites")}:</Typography>
                            </ListItemText>
                        </ListItem>
                        {orderedSites.map(renderSite)}
                    </List>
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ px: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={initialFilter.length === 0}
                    onClick={() => {
                        onSet([])
                        onClose()
                    }}
                >
                    {t("action.clear_filter")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={filter.length === 0}
                    onClick={() => {
                        onSet(filter)
                        onClose()
                    }}
                >
                    {t("action.filter")}
                </Button>
                <Button variant="outlined" color="primary" onClick={onClose}>
                    {t("action.close")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
