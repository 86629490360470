import { Typography } from "@mui/material"
import React from "react"
import { Navigate, Path, useLocation } from "react-router-dom"
import { retainConfig } from "../config/urls"
import useAuth from "./AuthProvider"
import useAuthorizer from "./AuthorizerProvider"

export interface RequireAuthProps {
    children: React.ReactNode
}

const toRedirectURL = (location: Path) => {
    return location.pathname + location.search + location.hash
}

export const RequireAuth = (props: RequireAuthProps) => {
    const { children } = props

    const { actor } = useAuth()
    const { initialized } = useAuthorizer()
    const location = useLocation()

    if (!actor) {
        return (
            <Navigate
                to={retainConfig({
                    pathname: "/login",
                    search: new URLSearchParams({ redirect_to: toRedirectURL(location) }).toString(),
                })}
                state={{ from: location }}
                replace
            />
        )
    }
    if (!initialized) {
        return <Typography>Loading user permissions...</Typography>
    }

    return <>{children}</>
}
