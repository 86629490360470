import { Box } from "@mui/material"
import { useState } from "react"
import { Navigate, useNavigate, useSearchParams } from "react-router-dom"
import useAuth from "../auth/AuthProvider"
import { LoginDialog } from "../components/LoginDialog"
import useConfig from "../config/Provider"
import { retainConfig, staticURL, urlTo } from "../config/urls"

export const Login = () => {
    const [messages, setMessages] = useState<string[]>([])

    const [searchParams] = useSearchParams()
    const redirectInput = searchParams.get("redirect_to")
    const redirectTo = redirectInput && redirectInput.startsWith("/") ? redirectInput : "/"
    const auth = useAuth()
    const navigate = useNavigate()
    const { mode } = useConfig()

    return (
        <Box
            width="100%"
            height="100%"
            style={{
                backgroundImage: `url(${staticURL(mode === "dark" ? "background.jpg" : "background-light.jpg")}`,
                backgroundSize: "cover",
            }}
        >
            {!auth.actor ? (
                // If there is no actor, let user log in.
                <LoginDialog
                    messages={messages}
                    onLogin={(email, password) => auth.login(email, password, setMessages, navigate, redirectTo)}
                />
            ) : (
                <Navigate to={retainConfig(urlTo(redirectTo))} replace />
            )}
        </Box>
    )
}
