import { LockPerson } from "@mui/icons-material"
import { t } from "i18next"
import { useMemo } from "react"
import { useParams } from "react-router-dom"
import { Operation } from "../api/Authz"
import { Customer } from "../api/Customer"
import { User } from "../api/User"
import { useCustomerPermission } from "../auth/AuthorizerProvider"
import { CustomerPage } from "../components/CustomerPage"
import { CustomerPermissions } from "../features/permissions/CustomerPermissions"

export interface CustomerPermissionsPageProps {
    actor: User
    customers: Customer[]
    showArchived: boolean
    setShowArchived: (value: boolean) => void
}

export function CustomerPermissionsPage(props: CustomerPermissionsPageProps) {
    const { actor, customers, showArchived, setShowArchived } = props

    const params = useParams()
    const customerID = parseInt(params.customerID || "-1")

    const customer = useMemo(() => customers.find((c) => c.ID === customerID), [customers, customerID])
    const allowed = useCustomerPermission(Operation.UI_VIEW_CUSTOMER_PERMISSIONS, customer?.ID)

    return (
        <CustomerPage
            actor={actor}
            customer={customer}
            page={{
                icon: <LockPerson />,
                title: t("dialog.customer_permissions.title"),
            }}
            customers={customers}
            allowed={allowed}
            showArchived={showArchived}
            setShowArchived={setShowArchived}
            render={(customer: Customer) => <CustomerPermissions customer={customer} />}
        />
    )
}
