import { Day, Hour, Minute, Second } from "../config/time"

export interface Playback {
    Delay: number
    IssuedAt: number
    Paused: boolean
    Speed: number
}

// This is to avoid rounding errors when jumping back and forward in time. Should be small enough
// to not be noticeable otherwise.
const LiveThreshold = 1 * Second

export const Live = () => {
    return NewPlayback(0, false, 1)
}
export const Paused = (playback: Playback) => NewPlayback(CurrentDelay(playback), true, playback.Speed)
export const Resumed = (playback: Playback) => NewPlayback(CurrentDelay(playback), false, playback.Speed)
export const AddDelay = (playback: Playback, delay: number) =>
    NewPlayback(CurrentDelay(playback) + delay, playback.Paused, playback.Speed)
export const SetDelay = (playback: Playback, delay: number) => NewPlayback(delay, playback.Paused, playback.Speed)
export const SetTime = (playback: Playback, time: number) =>
    NewPlayback(Date.now() - time, playback.Paused, playback.Speed)
export const SetSpeed = (playback: Playback, speed: number) =>
    NewPlayback(CurrentDelay(playback), playback.Paused, speed)
export const NewPlayback = (delay: number, paused: boolean, speed: number) => {
    if (delay <= LiveThreshold) {
        delay = 0
        speed = 1
        paused = false
    }
    return {
        IssuedAt: Math.round(Date.now()),
        Delay: Math.round(delay),
        Paused: paused,
        Speed: speed,
    }
}

export const IsLive = (playback: Playback) => {
    return CurrentDelay(playback) <= 0
}

export const CurrentDelay = (playback: Playback, now?: number) => {
    if (now === undefined) {
        now = Date.now()
    }
    const age = now - playback.IssuedAt
    const elapsed = playback.Paused ? 0 : age * playback.Speed
    return Math.max(0, age + playback.Delay - elapsed)
}

export const CurrentTime = (playback: Playback, now?: number) => {
    if (now === undefined) {
        now = Date.now()
    }
    return now - CurrentDelay(playback, now)
}

const pad2 = (n: number) => String(n).padStart(2, "0")

export const DelayToString = (delay: number) => {
    if (delay <= 0) return "now"

    const days = Math.floor(delay / Day)
    const hours = Math.floor((delay % Day) / Hour)
    const minutes = Math.floor((delay % Hour) / Minute)

    if (days > 0) {
        return `-${days}d ${hours}:${pad2(minutes)}`
    }
    return `-${hours}:${pad2(minutes)}`
}

export const EqualPlayback = (p1: Playback, p2: Playback, tolerance?: number) => {
    if (IsLive(p1) && IsLive(p2)) {
        return true
    }
    if (p1.Speed !== p2.Speed || p1.Paused !== p2.Paused) {
        return false
    }

    const now = Date.now()
    const d1 = CurrentDelay(p1, now)
    const d2 = CurrentDelay(p2, now)

    return Math.abs(d1 - d2) <= (tolerance || 0)
}
