import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    KeyboardDoubleArrowDown,
    KeyboardDoubleArrowUp,
    SettingsInputComponent,
} from "@mui/icons-material"
import { Box, Grid, IconButton, Tooltip, Typography, useTheme } from "@mui/material"
import { Stack } from "@mui/system"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { Operation } from "../../api/Authz"
import { SendCommandRequest } from "../../api/Command"
import { Site, Unit } from "../../api/Customer"
import { Event, Labels } from "../../api/Event"
import { useAlarmPermission } from "../../auth/AuthorizerProvider"
import { http, noSnackBar } from "../../backend/request"
import { request } from "../../config/headers"
import { monoEndpointURL } from "../../config/urls"
import { Thing, useAlarmEvent } from "../../services/AlarmEvent"
import { allEvents } from "../../services/EventStream"
import { ConnectionStatus } from "../common/ConnectionStatus"
import { OutputInfo } from "./OutputInfo"
import { PartitionBlock } from "./PartitionBlock"

const regexAlarmThingName = /([a-zA-Z0-9]+)\.alarm_[0-9]+\.((zone|partition|output)_([0-9]+))/
const regexAlarmThingNameExpectedSize = 5

export interface Partition {
    id: number
    displayName?: string
    thingName: string
    labels: Labels
    alarmID: number
    isSystem: boolean
}

export interface Zone {
    id: number
    displayName?: string
    thingName: string
    labels: Labels
    partitionID: number
    alarmID: number
    isSystem: boolean
}

export interface Output {
    id: number
    displayName?: string
    thingName: string
    labels: Labels
    alarmID: number
}

export interface State {
    alarm?: boolean
    alarmInMemory?: boolean
    violation?: boolean
    lowBattery?: boolean
    disconnected?: boolean
    bypass?: boolean
    armed?: boolean
    outputSwitch?: boolean
}

export interface AlarmProps {
    site: Site
    unit: Unit
}

export function NewAlarm(props: AlarmProps) {
    const { site, unit } = props
    const [partitions, setPartitions] = useState<Partition[]>([])
    const [zones, setZones] = useState<Zone[]>([])
    const [outputs, setOutputs] = useState<Output[]>([])
    const [states, setStates] = useState<Map<string, State>>(new Map())
    const [showOutputs, setShowOutputs] = useState(false)
    const [showDetails, setShowDetails] = useState(new Set())

    const { t } = useTranslation()
    const theme = useTheme()

    // Need to set alarmID dynamically later.
    const allowOutputOperation = useAlarmPermission(Operation.SWITCH_OUTPUT, unit, 1)

    const flipDetails = (partitionID: number) =>
        setShowDetails((v) => {
            const r = new Set(v)
            if (r.has(partitionID)) {
                r.delete(partitionID)
            } else {
                r.add(partitionID)
            }
            return r
        })

    const addOrUpdateState = (id: string, newState: Partial<State>) => {
        setStates((prevStates) => {
            const newStates = new Map(prevStates)
            if (newStates.has(id)) {
                const existingState = newStates.get(id)
                const updatedState = { ...existingState, ...newState }
                newStates.set(id, updatedState)
            } else {
                newStates.set(id, newState as State)
            }
            return newStates
        })
    }

    const upsertSorted = (array: any[], newElement: any): any[] => {
        const index = array.findIndex((element) => element.id >= newElement.id)
        if (index === -1) {
            return [...array, newElement]
        } else if (array[index].id === newElement.id) {
            return [...array.slice(0, index), newElement, ...array.slice(index + 1)]
        } else {
            return [...array.slice(0, index), newElement, ...array.slice(index)]
        }
    }

    const onThing = useCallback(
        (thing: Thing) => {
            const matches = thing.thing.match(regexAlarmThingName)
            if (matches?.length !== regexAlarmThingNameExpectedSize) {
                return
            }

            const addPartition = (newPartition: Partition) => {
                setPartitions((prev) => upsertSorted(prev, newPartition))
            }

            const addZone = (newZone: Zone) => {
                setZones((prev) => upsertSorted(prev, newZone))
            }

            const addOutput = (newOutput: Output) => {
                setOutputs((prev) => upsertSorted(prev, newOutput))
            }

            const unitName = matches[1]
            const objectName = matches[3] // "partition" or "zone".
            const objectID = Number(matches[4])

            if (unitName !== unit.ShortName) {
                return
            }
            const isSystem = thing.labels.get("member_system") === "true"
            const alarm = thing.labels.get("alarm")
            if (!alarm) {
                return
            }
            const alarmID = parseInt(alarm, 10)

            switch (objectName) {
                case "partition": {
                    addPartition({
                        id: objectID,
                        displayName: thing.displayName,
                        thingName: thing.thing,
                        isSystem: isSystem,
                        labels: thing.labels,
                        alarmID: alarmID,
                    })
                    break
                }
                case "zone": {
                    const partitionID = thing.labels.get("partition")
                    if (!partitionID) {
                        break
                    }
                    addZone({
                        id: objectID,
                        displayName: thing.displayName,
                        thingName: thing.thing,
                        isSystem: isSystem,
                        labels: thing.labels,
                        partitionID: parseInt(partitionID, 10),
                        alarmID: alarmID,
                    })
                    break
                }
                case "output": {
                    addOutput({
                        id: objectID,
                        displayName: thing.displayName,
                        thingName: thing.thing,
                        labels: thing.labels,
                        alarmID: alarmID,
                    })
                    break
                }
                default:
                    return
            }
        },
        [unit.ShortName]
    )

    const onEvent = useCallback((event: Event) => {
        const matches = event.thing.match(regexAlarmThingName)
        if (matches?.length !== regexAlarmThingNameExpectedSize) {
            return
        }

        let newState: State = {}
        const value = !!event.value
        switch (event.item) {
            case "alarm":
                newState.alarm = value
                break
            case "alarm_in_memory":
                newState.alarmInMemory = value
                break
            case "violation":
                newState.violation = value
                break
            case "low_battery":
                newState.lowBattery = value
                break
            case "disconnected":
                newState.disconnected = value
                break
            case "bypass":
                newState.bypass = value
                break
            case "armed":
                newState.armed = value
                break
            case "switch":
                newState.outputSwitch = value
                break
            default:
                return
        }
        const stateID = matches[2] // Examples: "partition_1" or "zone_2".
        addOrUpdateState(stateID, newState)
    }, [])

    const { online } = useAlarmEvent(site.ID, unit.ShortName, true, true, allEvents, onThing, onEvent)

    const sendCommand = useCallback(
        (item: string, value: number, c: any) => {
            const command: SendCommandRequest = {
                command: {
                    thing: c.thingName,
                    item: item,
                    command: {
                        number: value,
                    },
                },
            }
            http(`Sending command`, monoEndpointURL(`sites/${site.ID}/commands`), noSnackBar, {
                method: "POST",
                headers: request.headers,
                body: JSON.stringify(command),
            })
                .then((_) => console.log("Successfuly set item"))
                .catch((e) => console.log(e))
        },
        [site.ID]
    )

    const flipArmed = useCallback(
        (armed: boolean, partition: Partition) => sendCommand("arm", armed ? 0 : 1, partition),
        [sendCommand]
    )

    const flipBypass = useCallback(
        (bypass: boolean, zone: Zone) => sendCommand("bypass", bypass ? 0 : 1, zone),
        [sendCommand]
    )

    const flipOutputSwitch = useCallback(
        (switchOn: boolean, output: Output) => sendCommand("switch", switchOn ? 0 : 1, output),
        [sendCommand]
    )

    const partitionStates = useCallback((id: number): State | undefined => states.get("partition_" + id), [states])
    const zoneStates = useCallback((id: number): State | undefined => states.get("zone_" + id), [states])
    const outputStates = useCallback((id: number): State | undefined => states.get("output_" + id), [states])
    const outputLabel = (o: Output) => o.displayName || t("alarm.output_display_name", { id: o.id })
    const outputTooltip = (o: Output) =>
        `${t("alarm.output_display_name", { id: o.id })}${!o.displayName ? "" : ": " + o.displayName}.`

    return (
        <Stack sx={{ py: 2, px: 4, alignItems: "center", width: "100%" }}>
            <Box sx={{ width: "min(100%,1280px)" }}>
                <ConnectionStatus online={online} />
                <Grid container pb={5} spacing={1}>
                    <>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Stack direction="row" alignItems="center" pb={2}>
                                <Typography variant="h5" sx={{ flexGrow: 1 }}>
                                    {t("offering.alarm")}
                                </Typography>
                                <Box flexGrow={1} />
                                <Tooltip
                                    title={t(
                                        showDetails.size === partitions.length && showOutputs
                                            ? "alarm.hide_all_tooltip"
                                            : "alarm.show_all_tooltip"
                                    )}
                                    disableInteractive
                                >
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            if (showDetails.size === partitions.length && showOutputs) {
                                                setShowOutputs(false)
                                                setShowDetails(new Set())
                                            } else {
                                                setShowOutputs(true)
                                                setShowDetails(new Set(partitions.map((p) => p.id)))
                                            }
                                        }}
                                    >
                                        {showDetails.size === partitions.length && showOutputs ? (
                                            <KeyboardDoubleArrowUp />
                                        ) : (
                                            <KeyboardDoubleArrowDown />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8} lg={9} />
                        {partitions.map((partition) => (
                            <Grid
                                key={partition.id}
                                item
                                xs={12}
                                sm={showDetails.has(partition.id) ? 12 : 6}
                                md={showDetails.has(partition.id) ? 12 : 4}
                                lg={showDetails.has(partition.id) ? 12 : 3}
                            >
                                <PartitionBlock
                                    partition={partition}
                                    zones={zones}
                                    unit={unit}
                                    armed={!!partitionStates(partition.id)?.armed}
                                    alarm={!!partitionStates(partition.id)?.alarm}
                                    alarmInMemory={!!partitionStates(partition.id)?.alarmInMemory}
                                    showDetails={showDetails.has(partition.id)}
                                    zoneStates={zoneStates}
                                    flipArmed={(on) => flipArmed(on, partition)}
                                    flipBypass={flipBypass}
                                    flipDetails={() => flipDetails(partition.id)}
                                />
                            </Grid>
                        ))}

                        {outputs.length > 0 && (
                            <Grid item xs={12}>
                                <Grid container spacing={1} pb={5}>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <SettingsInputComponent htmlColor={theme.palette.text.secondary} />
                                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                                {t("alarm.outputs")}
                                            </Typography>
                                            <Box flexGrow={1} />
                                            <Tooltip
                                                title={t(
                                                    showOutputs
                                                        ? "alarm.hide_outputs_tooltip"
                                                        : "alarm.show_outputs_tooltip"
                                                )}
                                                disableInteractive
                                            >
                                                <IconButton color="primary" onClick={() => setShowOutputs((s) => !s)}>
                                                    {showOutputs ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={8} lg={9} />
                                    {showOutputs &&
                                        outputs.map((output) => (
                                            <OutputInfo
                                                key={output.id}
                                                label={outputLabel(output)}
                                                tooltip={outputTooltip(output)}
                                                disabled={!allowOutputOperation}
                                                outputSwitch={!!outputStates(output.id)?.outputSwitch}
                                                flipSwitch={(on) => flipOutputSwitch(on, output)}
                                            />
                                        ))}
                                </Grid>
                            </Grid>
                        )}
                    </>
                </Grid>
            </Box>
        </Stack>
    )
}
